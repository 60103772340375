import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function Hero(props){
    return(
      <section className="w-full mx-auto bg-gray-700 flex h-auto rounded-lg shadow-lg">
        <div className="container mx-auto my-auto">
          <div className="flex flex-row px-10 py-8 text-white">
            <div className="w-full md:w-1/2 pr-8">
              <h1 className="text-6xl tracking-wide font-bold mb-6">Terms of Use and Privacy Policy</h1>
            </div>
            <div className="w-full md:w-1/2">
              <div className="md:mx-48">
                <StaticImage
                  src="../images/gatsby-icon.png"
                  width={250}
                  alt="A hand-drawn koel"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

function Terms(props){
    return(
        <section className="w-full mx-auto bg-gray-700 flex h-auto rounded-lg shadow-lg">
        <div className="container mx-auto my-auto">
            <div className="flex flex-col w-full px-10 py-8 text-white">
            <SubHeading>Use of Service</SubHeading>
            <Paragraph>
              Using our service does not give you ownership of any intellectual property rights
              in our service or the content you access.
            </Paragraph>
            <SubHeading>Resale and Distribution</SubHeading>
            <Paragraph>
              By using this service you agree not to reproduce, duplicate, copy, sell, trade,
              resell, modify, create derivative works, or exploit for any commercial purposes,
              any portion of the service, use of the service, or access to the service or computer
              code that powers the service.
            </Paragraph>
            <SubHeading>Unauthorised Uses</SubHeading>
            <Paragraph>
              You agree not to modify, adapt or hack the service.
              You agree not to use the service to spam or otherwise generate unsolicited traffic
              directed at any other server, website, or other host computer.
              You agree not to modify any code proivded in relation to the service in any manner or form.
              You agree not to bypass any functionality of the service, or use it in a mannger other
              than in a way in which a reasonable person would consider normal permitted use.
            </Paragraph>
            <SubHeading>Liability</SubHeading>
            <Paragraph>
              Koelinks and all associated code, and all information provided herein are on an "as is" and
              "as available" basis without any warranty.
              To the extent permitted by law, Koelinks will not be responsibile for lost profits,
              revenues, or data, financial losses or indirect, special, consequential, exemplary,
              or punitive damages.
              To the extent permitted by law, Koelinks will not be liable for any loss or damage assoicated
              with the use of these services.
            </Paragraph>
            <SubHeading>About the Terms</SubHeading>
            <Paragraph>
              We may modify these terms at any time. If you do not agree to
              the modified terms, you should discontinue the use of our service.
              These terms do not create any third party beneficianry rights.
              Inaction on our behalf in the event of non-compliance with these terms 
              does not imply the giving up of any rights we may have, and as such 
              may take action at a future date.
              If any of these terms, on a per item basis, are rendered invalid, unenforaceable or void,
              the remaining terms will remain valid, enforceable and not be affected.
            </Paragraph>
            <SubHeading>Privacy Policy</SubHeading>
            <Paragraph>
              We are Koelinks.com
              In the course of our engagement of third parties to provide 
              analytics services about the use of this site, we may collect data. This data 
              can include cookies and usage data of our site.
              This data is not retained by Koelinks other than in conjunction
              with such third party services.
              Please contact us for further details about data retention.
            </Paragraph>
            </div>
        </div>
        </section>
    )
}

function SubHeading(props){
    return(
        <h2 className="text-4xl font-bold tracking-wider mb-2">
            {props.children}
        </h2>
    )
}

function Paragraph(props){
  return (
    <p className="text-xl leading-relaxed">
      {props.children}
    </p>
  )
}

const NotFoundPage = () => (
  <Layout>
    <SEO title="Terms of Use" />
    <h1>NOT FOUND</h1>
    <p>You just found a page that doesn't exist</p>
  </Layout>
)

export default NotFoundPage
